import Vuex from "vuex";
import Vue from "vue";
import dashboardOffline from "./Modules/Dashboard/DashboardOffline";
import dashboardOnline from "./Modules/Dashboard/DashboardOnline";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    dashboardOnline,
    dashboardOffline
  }
});