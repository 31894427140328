import { dayInfo } from "../../../Utility/Helpers"
import * as localForage from "localforage";
import axios from 'axios';
import { baseUrl } from "../../server";

const dashboardOffline = {
    namespaced: true,
    state: {
    },
    mutations: {

    },
    actions: { 
        async updateIndexedDb(context, payload) {
            const students = await localForage.getItem('students');
            const studentIndex = students.findIndex(item => item.id === payload.student.id);
            switch(payload.btn_name) {
                case 'ev_coming': {
                    const updatedDetails = {
                        ...payload.student,
                        attype: 'c',
                        btn_name: payload.btn_name,
                        begtime: {
                            hour: dayInfo()[3].split(":")[0],
                            microsecond: 0,
                            minute: dayInfo()[3].split(":")[1],
                            second: dayInfo()[3].split(":")[2],
                            "__class__": "time"
                        }
                    }
                    await students.splice(studentIndex, 1, updatedDetails);
                    await localForage.setItem('students', students);
                    return updatedDetails;
                }
                case 'ev_going': {
                    const updatedDetails = {
                        ...payload.student,
                        attype: 'c',
                        btn_name: payload.btn_name,
                        endtime: {
                            hour: dayInfo()[3].split(":")[0],
                            microsecond: 0,
                            minute: dayInfo()[3].split(":")[1],
                            second: dayInfo()[3].split(":")[2],
                            "__class__": "time"
                        }
                    }
                    await students.splice(studentIndex, 1, updatedDetails);
                    await localForage.setItem('students', students);
                    return updatedDetails;
                }
                case 'ev_ill': {
                    const updatedDetails = {
                        ...payload.student,
                        attype: 'i',
                        btn_name: payload.btn_name,
                        begtime: {
                            hour: dayInfo()[3].split(":")[0],
                            microsecond: 0,
                            minute: dayInfo()[3].split(":")[1],
                            second: dayInfo()[3].split(":")[2],
                            "__class__": "time"
                        }
                    }
                    await students.splice(studentIndex, 1, updatedDetails);
                    await localForage.setItem('students', students);
                    return updatedDetails;
                }
                case 'ev_absence_unexcused': {
                    const updatedDetails = {
                        ...payload.student,
                        attype: 'u',
                        btn_name: payload.btn_name,
                        begtime: {
                            hour: dayInfo()[3].split(":")[0],
                            microsecond: 0,
                            minute: dayInfo()[3].split(":")[1],
                            second: dayInfo()[3].split(":")[2],
                            "__class__": "time"
                        }
                    }
                    await students.splice(studentIndex, 1, updatedDetails);
                    await localForage.setItem('students', students);
                    return updatedDetails;
                }
                case 'ev_reset': {
                    await students.splice(studentIndex, 1, payload.student);
                    await localForage.setItem('students', students);
                }
            }
        },
        async addOfflineStudentIds(context, payload) {
            const oldIds = await localForage.getItem('ids');
            oldIds[payload.student.id] =  { 
                btn_name: payload.btn_name,
                time: payload.timingInfo[payload.timingInfo.length - 1] 
            };
            localForage.setItem('ids', oldIds );
        },
        async resetTemplate(context, payload) {
            return {
                ...payload.student,
                attype: null,
                msg: null,
                begtime: null,
                endtime: null,
                btn_name: 'ev_reset'
            }
        },
        async sendOfflineDataToServer(context, payload) {
            console.log('got called');
            const offlineData = await localForage.getItem('ids');
            const callsToBeMade = [];
            if (offlineData && Object.keys(offlineData).length > 0) {
                for (let studentId in offlineData) {
                    callsToBeMade.push(
                        {
                            method: 'post',
                            url: baseUrl,
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Session ${localStorage.getItem('token')}`
                            },
                            data : {
                                "params": decideParams(studentId, offlineData[studentId].btn_name),
                                "id": 1,
                                "method": `model.kitaabr_modattendance.attendance.${offlineData[studentId].btn_name}`
                            }   
                        }
                    );
                }
            }
            function decideParams(studentId, choice) {
                const fullDate = new Date().toLocaleDateString() .split('/');
                const dateToSend = `${fullDate[2]}-${fullDate[0]}-${fullDate[1]}`;
                if (choice === 'ev_reset') {
                    return [[parseInt(studentId)], {}];
                } else {
                    return [[parseInt(studentId)], dateToSend, offlineData[studentId].time.trim(), {}];
                }
            }
            function postAllData(){
                return axios.all(callsToBeMade.map(updateData));
            }
            function updateData(each) {
                return axios(each).then(res => res).catch(err => console.log(err));
            }
            const finish = await postAllData();
            return finish;
        },
        async setOfflineIdsArray() {
            localForage.getItem('ids')
            .then((res) => {
                if (res === null) {
                    localForage.setItem('ids', {});
                }
            })
        },
    },
    getters: { 
        
    }
}

export default dashboardOffline;