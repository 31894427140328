<template>
    <v-snackbar v-model="show" :color="color" :timeout="timeout">
      {{ message }}
    </v-snackbar>
</template>

<script>
export default {
    name: 'Alert',
    data: () => ({
      show: false,
      message: '',
      timeout: 6000,
      color: ''
    }),
    methods: {
        showAlert({ message, color }) {
            this.show = true;
            this.message = message;
            this.color = color;
        }
    }
}
</script>
