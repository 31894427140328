"use-strict";

export const baseUrl = "http://vm8-devubu-tryton.ijov6dgeqdbs9j6s.myfritz.net:8001/kasse1/";
export const apiSettings = (method) => {
  return {
    method: method,
      url: baseUrl,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Session ${localStorage.getItem('token')}`
    },
  }
}

