import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "../views/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    name: "Login Page",
    path: "/",
    component: LoginPage
  },
  {
    path: "*",
    component: LoginPage
  },
  {
    path: "",
    component: () => import(/* webpackChunkName: "MainPage" */ '../views/Main.vue'),
    meta: { requiresAuth: true },
    children: [
      { 
        path: "/dashboard", 
        component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue'),
        meta: { requiresAuth: true }
      },
      { 
        path: "/extra", 
        component: () => import(/* webpackChunkName: "Extra" */ '../views/Extra.vue'),
        meta: { requiresAuth: true }
      }
    ]
  }
];

const router = new VueRouter({
  routes,
  mode: 'history'
});

router.beforeEach((to, from, next) => { 
  if (isAuthenticated(to)) {
    const token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : null;
    if (token === null) {
      next("/");
    }
  }
  next();
});

function isAuthenticated(to) {
  return to.matched.some((record) => record.meta.requiresAuth);
}

export default router;
