<template>
  <div class="main">
    <Alert/>
    <div class="tint" :style="{ width: $vuetify.breakpoint.xs ? '80%' : '40%' }">
      <div class="form-holder">
        <h1 class="lgn">
          Login<span
            class="iconify"
            data-icon="ic:baseline-settings"
            data-inline="false"
          ></span>
        </h1>
        <form class="sign-in-form" @submit.prevent="login()">
          <input
            required
            type="text"
            v-model="username"
            @change="userHandler('username')"
            placeholder="Username"
          />
          <input
            required
            type="password"
            v-model="password"
            @change="userHandler('password')"
            placeholder="Password"
          />
          <div class="login-button-container">
            <v-btn @click="login()" elevation="3" medium dark raised :loading="signingIn">Sign In</v-btn>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Alert from "../components/Alert";

export default {
  name: "Login",
  components: { Alert },
  data: () => ({
    username: "",
    password: "",
    type: "primary",
    value: false,
    signingIn: false,
  }),
  methods: {
    login() {
        if (this.loginData.username === '' || this.loginData.password === '') {
          this.showAlert('error', 'Username or Password Empty', true);
          this.hideAlert();
        } else {
          this.signingIn = true;
          this.$store.dispatch('dashboardOnline/login', { username: this.loginData.username, password: this.loginData.password })
          .then((res) => {
            if (res.status === 200) {
              const key = `${this.loginData.username}:${res.data[0]}:${res.data[1]}`;
              const token = Buffer.from(key).toString('base64');
              localStorage.setItem('token', token);
              this.$router.push({ path: '/dashboard' });
              this.signingIn = false;
            } else {
              this.signingIn = false;
              this.showAlert('error', 'Please check your credentials', true);
              this.hideAlert();
            }
          }).catch((err) => {
            this.signingIn = false;
            this.showAlert('error', 'Please check your credentials', true);
            this.hideAlert();
          });
        }
    },
    userHandler(entry) {
      switch (entry) {
        case "username":
          this.$store.commit("dashboardOnline/setUsername", { username: this.username });
          break;
        case "password":
          this.$store.commit("dashboardOnline/setPassword", { password: this.password });
          break;
      }
    },
    showAlert(type, message, show) {
      this.$store.commit('dashboardOnline/setAlertMessage', { message, type, show });
    },
    hideAlert() {
      setTimeout(() => this.showAlert('info', 'adsd', false), 2000);
    }
  },
  computed: {
    ...mapGetters({
      loginData: 'dashboardOnline/getLoginData'
    })
  }
};
</script>

<style lang="scss" scoped>

$tabletvertical: "(min-width: 660px) and (max-width: 1023px)";
$tablet: "(min-width: 600px) and (max-width: 1023px)";
$desktop: "(min-width: 1024px)";

.lgn {
  color: black;
}
.main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background: url('../assets/hands.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.tint {
  height: 45%;
  width: 40%;
  border-radius: 15px;
  background-color: rgba(179, 179, 179, 0);  
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgray;
}

@media #{$tablet} {
 .tint {
  width: 70%;
 }
}

.form-holder {
  box-sizing: border-box;
  color: white;
  height: 70%;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sign-in-form {
  width: 90%;
  margin-top: 8%;
  display: flex;
  flex-direction: column;
}
h1 {
  font-size: 40px;
  color: white;
}
input {
  height: 40px;
  padding-left: 2%;
  border-radius: 10px;
  color: black;
  background: white;
  border: 1px dotted gray;
  font-size: 12px;
  margin-bottom: 3%;
}
input:focus {
  outline: none;
  border: 1px solid #ff791a;
}
.login-button-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 1%;
}
.login:focus {
  border: 2px solid white;
}
</style>
