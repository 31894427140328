import axios from "axios";
import * as localForage from "localforage";
import { baseUrl, apiSettings } from "../../server.js";

const dashboardOnline = {
    namespaced: true,
    state: {
        login: {
          username: '',
          password: ''
        },
        globalState: {
          loading: false,
          alertMessage: '',
          showAlert: false,
          online: navigator.onLine
        },
        dashboardState: {
          students: [], 
          searchQuery: '',
          searched: [],
          loggingIn: false,        
        },
      },
      mutations: {
        setUsername(state, payload) {
          state.login.username = payload.username;
        },  
        setPassword(state, payload) {
          state.login.password = payload.password;
        },
        setAlertMessage(state, payload) {
          state.globalState.alertMessage = payload.message;
          state.globalState.showAlert = payload.show;
        },
        setNetworkStatus(state, payload) {
          state.globalState.networkStatus = payload.status;
        },
        // STATUSSES
        startLoading(state) {
          state.globalState.loading = true;
        },
        stopLoading(state) {
          state.globalState.loading = false;
        },
        startLoggingIn(state) {
          state.dashboardState.loggingIn = true;
        },
        stopLoggingIn(state) {
          state.dashboardState.loggingIn = false;
        },
        // STATUSES
    
        changeTheme(state) {
          state.theme.isDark = !state.theme.isDark;
        },
        handleAttendance(state, payload) {
          if (payload.type === 'reset') {
              const filtered = state.dashboardState.students.filter((student) => student.id !== payload.data.id);
              const final = [payload.data].concat(filtered);
              state.dashboardState.students = final;
          }
          if (payload.type === 'login') {
              const itemIndex = state.dashboardState.students.findIndex((student) => student.id === payload.data.id);
              state.dashboardState.students.splice(itemIndex, 1, payload.data);
          }
          if (payload.type === 'logout' || payload.type === 'ill' || payload.type === 'unexcused') {
              const filtered = state.dashboardState.students.filter((student) => student.id !== payload.data.id);
              state.dashboardState.students = [ ...filtered, payload.data ];
          }
        },
        setStudents(state, payload) {
          const manipulated = payload.data.result.map((student) => {
            return {
              ...student,
              name: student.name,
              firstname: student.name.replace(","," ").split(" ")[1],
              lastname: student.name.replace(","," ").split(" ")[0],
              loggingIn: false,
              loggingOut: false,
              isIll: false,
              isUnexcused: false
            }
          });
          state.dashboardState.students = manipulated;
          localForage.setItem('students', manipulated)
          .then(() => {
            state.globalState.loading = false;
          })
          .catch((err) => {
            console.log(err);
            state.globalState.loading = false;
          })
          
        },
        updateStudentById(state, payload) {
          const itemIndex = state.dashboardState.students.findIndex((student) => student.id === payload.id[0]);
          const updatedData = { 
            ...state.dashboardState.students[itemIndex], 
            ...payload.resp.data.result[0], 
            name: payload.resp.data.result[0].name,
            firstname: payload.resp.data.result[0].name.replace(","," ").split(" ")[1],
            lastname: payload.resp.data.result[0].name.replace(","," ").split(" ")[0],
            loggingIn: false, 
            loggingOut: false, 
            isIll: false, 
            isUnexcused: false,
          };
          state.dashboardState.students.splice(itemIndex, 1, updatedData);
        }
      },
      actions: {
        // 
        // 
        // LOGIN
        async login(context, payload) {
          const loginData = {
            "method": "common.db.login", 
            "params": [payload.username, {"password" : payload.password}, "de"] 
          };
          const config = {
            method: 'post',
            url: baseUrl,
            headers: { "Content-Type": "application/json" },
            data : loginData
          };
          const data = await axios(config);
          return data;
        },
        // 
        // 
        // ACTION TO GET ALL STUDENT IDS
        async getAllStudentIds() {
          const payload = {
            "params":[[],{}],
            "id":3,
            "method":"model.kitaabr_modattendance.attendance.search"
          };
          const config = {
            ...apiSettings('post'),
            data : payload
          };
          const data = await axios(config);
          return data;
        },
        // 
        // 
        // ACTION TO GET SELECTED DATA 
        updateDataForStudent(context, payload) {
          const data = {
            "params":[[...payload.id],{}],
            "id":3,
            "method":"model.kitaabr_modattendance.attendance.read"
          };
          const config = {
            ...apiSettings('post'),
            data : data
          };
          axios(config).then(resp => {
            context.commit('updateStudentById', { resp, id: payload.id  });
            context.commit('stopLoading');
          });
        },
        //
        //  
        // ACTION TO GET AND SET ALL STUDENTS
        getAllStudents(context, payload) {
          const data = {
            "params":[[...payload.ids],{}],
            "id":3,
            "method":"model.kitaabr_modattendance.attendance.read"
          };
          const config = {
            ...apiSettings('post'),
            data : data
          };
          axios(config).then(resp => context.commit('setStudents', resp));
        },
        // 
        // 
        // Handle Action
        async handleAction(context, payload) {
          const toSend = {
            "params":[[payload.id], {}],
            "id":1,
            "method":`model.kitaabr_modattendance.attendance.${payload.btn_name}`
          };
          const config = {
            ...apiSettings('post'),
            data : toSend
          };
          const data = await axios(config);
          return data;
        },
        // 
        // 
        // RESET THE STUDENT 
        async resetStudent(context, payload) {
          const toSend = {
            "params":[[payload.id], {}],
            "id":1,
            "method":"model.kitaabr_modattendance.attendance.ev_reset"
          };
          const config = {
            ...apiSettings('post'),
            data : toSend
          };
          const data = await axios(config);
          return data;
        },
        // 
        // 
        // POST NOTES FOR STUDENT
        async saveNoteToServer(context, payload) {
          const toSend = {
            "params":[[payload.id], payload.noteBody ,{}],
            "id":1,
            "method":"model.kitaabr_modattendance.attendance.ev_note"
          };
          const config = {
            ...apiSettings('post'),
            data : toSend
          };
          const data = await axios(config);
          return data;
        },
      },
      getters: {
        // STATUTES
        getLoadingStatus(state) {
          return state.globalState.loading;
        },
        getLoggingInStatus(state) {
          return state.dashboardState.loggingIn;
        },
        getLoginData(state) {
          return {
            username: state.login.username,
            password: state.login.password
          }
        },
        getAlertData(state) {
          return {
            message: state.globalState.alertMessage,
            showAlert: state.globalState.showAlert
          }
        },
        // STATUSES
    
        dayInfo() {
          let options = {  weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
          let prnDt = [ new Date().toLocaleTimeString('en-us', options) ];
          return prnDt[0].split(',');
        },
        getStudents(state) {
          return state.dashboardState.students;
        },
        getServerStudents(state) {
          return state.dashboardState.serverStudents;
        },
        presentStudents(state) {
          return state.dashboardState.students.filter((student) => student.attype === 'c');
        },
        absentStudents(state) {
          return state.dashboardState.students.filter((student) => student.attype !== 'c' && student.attype === 'i' || student.attype === 'u');
        },
        illStudents(state) {
          return state.dashboardState.students.filter((student) => student.attype === 'i');
        },
        unExcusedStudents(state) {
          return state.dashboardState.students.filter((student) => student.attype === 'u');
        },
        loggedOutStudents(state) {
          return state.dashboardState.students.filter((student) => student.endtime !== null);
        },
        getNetworkStatus(state){
          return state.globalState.online;
        },
      },
}

export default dashboardOnline;